import { ENV } from "@/core/config/env";

const calendarRouter = [
    {
        path: `${ENV.PREFIX_URL}/calendar`,
        component: () => import("@/view/layout/Layout"),
        children: [
            {
                path: "/",
                name: "calendar",
                component: () => import("@/view/pages/calendar/Calendar.vue"),
            }
        ]
    }
];

export default calendarRouter