import ApiService from "@/core/services/api.service";

export const listRental = {
    listCategory: (data = null) => {
        return ApiService.get("/category");
    },
    list: (data = null) => {
        return ApiService.get("/rental-fee");
    },
    detailEquipment: (id = null) => {
        return ApiService.get("/rental-fee/detail/"+id );
    },
}
