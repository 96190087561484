import { ENV } from "@/core/config/env";

const bookingRouter = [
    {
        path: `${ENV.PREFIX_URL}/booking`,
        component: () => import("@/view/layout/Layout"),
        children: [
            {
                path: "/",
                name: "booking",
                component: () => import("@/view/pages/booking/Booking.vue"),
            },
            {
                path: "preview",
                name: "booking-detail",
                component: () => import("@/view/pages/booking/Detail.vue"),
                beforeEnter: (to, from, next) => {
                    let order = localStorage.getItem('order');
                    if (order) {
                        next();
                    } else {
                        let url = to.matched[0].path;
                        window.location.href=url
                    }
                }
            }
        ]
    }
];

export default bookingRouter