import { ENV } from "@/core/config/env";

const orderRouter = [
    {
        path: ENV.PREFIX_URL,
        component: () => import("@/view/layout/Layout"),
        children: [
            {
                path: "order/:id",
                name: "order-detail",
                component: () => import("@/view/pages/order/Detail.vue"),
            },
        ],
    }
];

export default orderRouter