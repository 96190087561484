import { listRental } from "@/core/services/apis/rental";
import { STATUS_CODE } from "@/core/config/constant";

/* Status code */
const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE

export const LIST_RENTAL = "listRental";
export const GET_LIST_RENTAL = "getListRental";
export const LIST_CATEGORY = "listCategory";
export const GET_LIST_CATEGORY = "getListCategory";
export const DETAIL_EQUIPMENT = "detailEquipment";
export const GET_DETAIL_EQUIPMENT = "getDetailEquipment";

const state = {
    listRental: [],
    listCategory: [],
    detailEquipment: {},
};

const getters = {
    listRental:state => state.listRental,
    listCategory:state => state.listCategory,
    detailEquipment:state => state.detailEquipment,
}

const actions = {
    async [GET_LIST_RENTAL](context){
        try {
            let response = await listRental.list();
            if (response.data.code == SUCCESS) {
                context.commit(LIST_RENTAL, response.data.data);
                
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [GET_LIST_CATEGORY](context){
        try {
            let response = await listRental.listCategory();
            if (response.data.code == SUCCESS) {
                context.commit(LIST_CATEGORY, response.data.data);
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
    async [GET_DETAIL_EQUIPMENT](context, id){
        try {
            let response = await listRental.detailEquipment(id);
            if (response.data.code == SUCCESS) {
                context.commit(DETAIL_EQUIPMENT, response.data.data);
            } else {
                throw response.data.message;
            }
        } catch (e) {
            if (e.response.data.code == ERROR_SERVER) {
                context.commit(SET_ERROR, e.response.data.message);
                throw e.response.data.message
            } else {
                throw e;
            }
        }
    },
}

const mutations = {
    [LIST_RENTAL](state, data) {
        state.listRental = data;
    },
    [LIST_CATEGORY](state, data) {
        state.listCategory = data;
    },
    [DETAIL_EQUIPMENT](state,data) {
        state.detailEquipment = data
    },
}

export default {
    state,
    actions,
    mutations,
    getters
}