import ApiService from "@/core/services/api.service";

export const CalendarApi = {
    getDataCalendar: (params = null) => {
        if (params) {
            return ApiService.get(`/calendar?${params}`);
        }
        return ApiService.get("/calendar");
    },

    getDataCalendarRestTime: (params = null) => {
        if (params) {
            return ApiService.get(`/calendar/rest-time?${params}`);
        }
        return ApiService.get("/calendar/rest-time");
    },

    findBooking:(data) => {
        return ApiService.post(`/equipment/find-booking`, data);
    },
} 