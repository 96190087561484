import Vue from "vue";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, max } from 'vee-validate/dist/rules';
import i18n from "@/core/plugins/vue-i18n";
import moment from "moment";


Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

extend('required', {
  ...required,
  message: (fieldName, placeholders) => {
    return i18n.t('ER_02', {field: fieldName})
}
});

extend('max', {
    validate(value, { length }) {
        return value.length <= length;
    },
    params: ['length'],
    message: (fieldName, placeholders) => {
        return i18n.t('ER_03', {field: fieldName, length: placeholders.length})
    }
});

// check all space value
extend('spaceEmpty', {
  computesRequired: true,
  validate: value => {
    let valid = true;
   if (value.length !== 0 && !value.replace(/\s/g, '').length) {
        valid = false
      }
    return valid;
  },
  message: i18n.t('M02')
});

// check password
extend('passwordRegex', {
  computesRequired: true,
  validate: value => value.match(/^[a-zA-z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{}~]{6,12}$/u),
  message: i18n.t('M18')
});
// check name katakana
extend('katakanaRegex', {
  computesRequired: true,
  validate: value => value.match(/^[ァ-ヴーｧ-ﾝﾞﾟ]+$/u),
  message: i18n.t('M37')
});

// check phone
extend('phoneRegex', {
  computesRequired: true,
  validate: value => value.match(/^[0-9().-]*$/),
  message: (fieldName, placeholders) => {
    return i18n.t('ER_18', {field: fieldName})
  }
})

//check email
extend('emailRegex', {
    computesRequired: true,
    validate: value => value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g),
    message: i18n.t('ER_06')
})

extend('maxLength', {
    validate(value, { length }) {
        return parseInt(value) <= parseInt(length);
    },
    params: ['length'],
    message: (fieldName, placeholders) => {
        return i18n.t('ER_12', {field: fieldName, length: placeholders.length})
    }
})

// check number
extend('numberRegex', {
  computesRequired: true,
  validate: value => value.match(/^[0-9.+]*$/),
  message: (fieldName, placeholders) => {
    return i18n.t('ER_18', {field: fieldName})
}
})

extend('checkDateAdd7Day', {
  computesRequired: true,
  validate: (value) => {
    let dateOfNextWeek = moment().add('days', 7).format('YYYY-MM-DD');
    return value > dateOfNextWeek
  },
  message: i18n.t('ER_10')
})

extend('checkHours', {
  computesRequired: true,
  params: ['target'],
  validate: (value, { target }) => {
    let start = moment(target, "HH:mm");
    let end = moment(value, "HH:mm");
    let duration = moment.duration(end.diff(start));
    let hours = duration.asHours();
    return hours > 0.5;
  },
  message: i18n.t('ER_11')
})
  