import JwtService from "@/core/services/jwt.service";
import { CalendarApi } from "@/core/services/apis/calendar";
import { STATUS_CODE } from "@/core/config/constant";

/* Status code */
const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE

export const GET_DATA_CALENDAR = "getDataCalendar";
export const GET_DATA_CALENDAR_REST_TIME = "getDataCalendarRestTime";
export const GET_DATA_FIND_BOOKING = "getDataFindBooking";

const state = {
    errors: null,
    users: {},
    isAuthenticated: !!JwtService.getToken(),
};

const getters = {
    
}

const actions = {
    async [GET_DATA_CALENDAR](context, params) {
        try {
            const response = await CalendarApi.getDataCalendar(params);
            return response.data;
        } catch (e) {
            return e;
        }
    },

    async [GET_DATA_CALENDAR_REST_TIME](context, params) {
        try {
            const response = await CalendarApi.getDataCalendarRestTime(params);
            return response.data;
        } catch (e) {
            return e;
        }
    },

    async [GET_DATA_FIND_BOOKING](context, data) {
        try {
            const response = await CalendarApi.findBooking(data);
            return response.data;
        } catch (e) {
            return e;
        }
    },
}

const mutations = {

}

export default {
    state,
    actions,
    mutations,
    getters
}