import ApiService from "@/core/services/api.service";

export const BookingApi = {
    getEquipment: (params = null) => {
        if (params) {
            return ApiService.get(`/equipment?${params}`);
        }
        return ApiService.get("/equipment");
    },

    createBooking: (params) => {
        return ApiService.post("/booking", params);
    },

    checkTimeBooking: (params) => {
        return ApiService.post("/booking/check-time-booking", params);
    },

    checkStatusBooking: (params) => {
        return ApiService.get(`/booking/check-status-booking?${params}`);
    },
} 