import { ENV } from "@/core/config/env";

const termRouter = [
    {
        path: ENV.PREFIX_URL,
        component: () => import("@/view/layout/Layout"),
        children: [
            {
                path: "term",
                name: "term-user",
                component: () => import("@/view/pages/term/Index.vue"),
            }
        ]
    }
]


export default termRouter