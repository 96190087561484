
export const ENV = {
    //PREFIX_URL: '/user',
	PREFIX_URL: '',
    API_MODE: 'api',	
	//PREFIX_FOLDER: '/user',
	PREFIX_FOLDER: '',
	//API_URL: 'https://koxbooking.runsystem.work/server/api/user',
    //APP_API_URL: 'https://koxbooking.runsystem.work/server/api/',
	API_URL: 'https://api.takeoff7-booking.jp/server/api/user',
    APP_API_URL: 'https://api.takeoff7-booking.jp/server/api/',
    LOCALE : 'ja'
}

export default ENV;
