import JwtService from "@/core/services/jwt.service";
import { OrderApi } from "@/core/services/apis/order";

import { STATUS_CODE } from "@/core/config/constant";

/* Status code */
const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE

export const DETAIL_ORDER = 'detailOrder';
export const CHANGE_STATUS = 'changeStatus';
export const DATA_DETAIL = 'dataDetail';
export const PROCESS_BOOKING = 'processBooking';


const state = {
    errors: null,
    dataDetail: {}
};

const getters = {
    dataDetail(state) {
        return state.dataDetail;
    },
}

const actions = {
    async [DETAIL_ORDER](context, params) {
        try {
            const response = await OrderApi.detailOrder(params);
            if(response.data.data.end_time){
                  this.end_time = response.data.data.end_time;
                }
            if(response.data.code == SUCCESS){
                context.commit(DATA_DETAIL,response.data.data)
            }
        } catch (e) {
            return e;
        }
    },
    async [CHANGE_STATUS](context, data) {
        try {
            const response = await OrderApi.changeStatus(data);
            return response.data;
        } catch (e) {
            return e;
        }
    },

    async [PROCESS_BOOKING](context, data) {
        try {
            const response = await OrderApi.processBooking(data);
            return response.data;
        } catch (e) {
            return e;
        }
    },
}

const mutations = {
    [DATA_DETAIL](state,data){
        state.dataDetail = data
    }
}

export default {
    state,
    actions,
    mutations,
    getters
}