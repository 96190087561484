import Vue from "vue";
import Router from "vue-router";
import calendarRouter from "@/router/calendar";
import transactionRouter from "@/router/transaction";
import rentalRouter from "@/router/rental";
import policyRouter from "@/router/policy";
import orderRouter from "@/router/order";
import bookingRouter from "@/router/booking";
import termRouter from "@/router/term";

import { ENV } from "@/core/config/env";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: ENV.PREFIX_URL,
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "user",
          redirect: to => {
            return { name: 'calendar' }
          }
        },
      ]
    },
    ...calendarRouter,
    ...transactionRouter,
    ...rentalRouter,
    ...policyRouter,
    ...orderRouter,
    ...bookingRouter,
    ...termRouter,
    {
      // the 404 route, when none of the above matches
      path: "/admin/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    },
    {
      // the 404 route, when none of the above matches
      path: "*",
      name: "dynamic",
      component: () => import("@/view/pages/error/Error-6.vue")
    }
  ]
});
