import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { AuthApi } from "@/core/services/apis/auth";
import { STATUS_CODE } from "@/core/config/constant";

/* Status code */
const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const SEND_FORGOT_PASSWORD = "sendForgotPassword";
export const RESET_PASSWORD = "resetPassword";
export const UPDATE_PROFILE = "updateProfile";
export const CHECK_PASS = "checkPassword";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  async [LOGIN] (context, credentials) {
    try {
      const {data} = await AuthApi.login(credentials);
      if(data.data.token){
        data.data.user.token = data.data.token;
        context.commit(SET_AUTH, data.data);
      }
      return data
    } catch (err) {
      return err
    }
  },

  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("register", credentials)
        .then(({ data }) => {
          data.data.user.token = data.data.token;
          context.commit(SET_AUTH, data.data);
          resolve(data);
        })
        .catch((response) => {
          var arr = [];
          if (response.data.email) {
            arr.push(response.data.email[0]);
          }
          if (response.data.phone) {
            arr.push(response.data.phone[0]);
          }
          context.commit(SET_ERROR, arr);
          reject(response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("auth/verify")
        .then(({ data }) => {
          data.data.user.token = data.data.token;
          context.commit(SET_AUTH, data.data);
        })
        .catch(({ response }) => {
          context.commit(PURGE_AUTH);
          // context.commit(SET_ERROR, response.data.errors);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },

  async [SEND_FORGOT_PASSWORD](context, params) {
    try {
      const response = await AuthApi.sendForgotPassword(params);
      return response.data
    } catch (err) {
      return err
    }
  },

  async [RESET_PASSWORD](context, params) {
    try {
      const response = await AuthApi.resetPassword(params);
      return response.data
    } catch (err) {
      return err
    }
  },
  
  async [UPDATE_PROFILE](context, params) {
    try {
      const response = await AuthApi.updateProfile(params);
      return response.data
    } catch (err) {
      return err
    }
  },

  async [CHECK_PASS](context, params) {
    try {
      const response = await AuthApi.checkPass(params);
      return response.data
    } catch (err) {
      return err
    }
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user.user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
