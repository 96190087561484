import ApiService from "@/core/services/api.service";

export const OrderApi = {
    detailOrder: (id) => {
        return ApiService.get(`/booking/${id}`);
    },
    changeStatus:(data) => {
        return ApiService.post(`/booking/change-status-cancel`, data);
    },
    processBooking:(data) => {
        return ApiService.post(`/payment/process-booking`, data);
    },
} 