import JwtService from "@/core/services/jwt.service";
import { BookingApi } from "@/core/services/apis/booking";
import { STATUS_CODE } from "@/core/config/constant";
import * as Cookies from 'js-cookie'

/* Status code */
const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE

export const GET_EQUIPMENT = "getEquipment";
export const CREATE_BOOKING = "createBooking";
export const CHECK_TIME_BOOKING = "checkTimeBooking";
export const PREVIEW_BOOKING = "previewBooking";
export const CHECK_STATUS_BOOKING = "checkStatusBooking";

export const SET_PREVIEW_BOOKING = "setPreviewBooking";

const state = {
    errors: null,
    users: {},
    isAuthenticated: !!JwtService.getToken(),
    order: {},
};

const getters = {
    getOrder(state) {
        let order = localStorage.getItem('order');
        order = JSON.parse(order);
        state.order = order;
        return state.order;
    },
}

const actions = {
    async [GET_EQUIPMENT](context, params) {
        try {
            const response = await BookingApi.getEquipment(params);
            return response.data;
        } catch (e) {
            return e;
        }
    },

    async [CREATE_BOOKING](context, params) {
        try {
            const response = await BookingApi.createBooking(params);
            return response.data;
        } catch (e) {
            return e;
        }
    },

    async [CHECK_TIME_BOOKING](context, params) {
        try {
            const response = await BookingApi.checkTimeBooking(params);
            return response.data;
        } catch (e) {
            return e;
        }
    },

    [PREVIEW_BOOKING](context, params) {
        try {
            context.commit(SET_PREVIEW_BOOKING, params);
        } catch (e) {
            return e;
        }
    },

    async [CHECK_STATUS_BOOKING](context, params) {
        try {
            const response = await BookingApi.checkStatusBooking(params);
            return response.data;
        } catch (e) {
            return e;
        }
    }
}

const mutations = {
    [SET_PREVIEW_BOOKING](state, data) {
        state.order = data;
        localStorage.setItem('order', JSON.stringify(state.order));
    }
}

export default {
    state,
    actions,
    mutations,
    getters
}