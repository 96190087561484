import JwtService from "@/core/services/jwt.service";
import { TransactionApi } from "@/core/services/apis/transaction";
import { STATUS_CODE } from "@/core/config/constant";

/* Status code */
const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE

export const LIST_TRANSACTION = "listTransaction";

const state = {
    errors: null,
    users: {},
    isAuthenticated: !!JwtService.getToken(),
    listTransaction: []
};

const getters = {
    
}

const actions = {
    async [LIST_TRANSACTION](context, params) {
        try {
            const response = await TransactionApi.listTransaction(params);
            context.state.listTransaction = response.data.data;
            return response.data;
        } catch (e) {
            context.commit(SET_ERROR, e.message);
            return e;
        }
    },
}

const mutations = {

}

export default {
    state,
    actions,
    mutations,
    getters
}