import { ENV } from "@/core/config/env";

export const SOCIAL_TYPE = {
    1: 'Google',
    2: 'Apple',
    3: 'Facebook',
    4: 'Twitter',
    5: 'LINE',
    6: 'Mail',
}

export const USER_STATUS = {
    'active': 1,
    'block': 2,
}

export const STATUS_CODE = {
   AUTHENTICATE: 401,
   ERROR_SERVER: 500,
   BAD_REQUEST: 422,
   SUCCESS: 200,
   NOT_FOUND: 404,
   HTTP_BAD_REQUEST: 400,
}

export const USER_TYPE = {
    'internal': 0,
    'partner': 1,
}

export const STAFF_EQUIPMENT = {
    STAFF: 1,
    EQUIPMENT: 0,
}