import { ENV } from "@/core/config/env";

const policyRouter = [
    {
        path: ENV.PREFIX_URL,
        component: () => import("@/view/layout/Layout"),
        children: [
            {
                path: "policy",
                name: "pivacy-policy",
                component: () => import("@/view/pages/policy/Index.vue"),
            }
        ]
    }
]


export default policyRouter